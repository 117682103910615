import { Router } from 'react-justanother-router';

import { ActionModal, ListPickerRemoveModal } from '@/shared';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '@app/apollo';
import { TariffOrderInfoModal } from '@entities/Tariff';
import {
  AddUserGroupModal,
  AddUserToGroupModal,
  DeactivateUserOrganizerModal,
  RemoveUserFromGroupModal,
  RemoveUserGroupModal,
} from '@features/Contact';
import {
  ArchiveEventModal,
  PublishEventModal,
  RemoveEventModal,
} from '@features/Event';
import { DisableOrderModal } from '@features/Orders';
import { CreateReferralModal, RemoveReferralModal } from '@features/Referral';
import { RemoveBaseTariffModal } from '@features/Tariff';
import { WithdrawCancelModal } from '@features/Withdraw';
import { GoblinProvider, ModalProviderProps, Toaster } from '@letsdance/ui-kit';

import './configs';

import { history, renderer, router } from './router';

import './styles/index.scss';

const componentDictionary: ModalProviderProps['components'] = {
  action: ActionModal,
  addUserToGroup: AddUserToGroupModal,
  archiveEvent: ArchiveEventModal,
  cancelWithdraw: WithdrawCancelModal,
  createReferral: CreateReferralModal,
  createUserGroup: AddUserGroupModal,
  deactivateUserOrganizer: DeactivateUserOrganizerModal,
  disableOrder: DisableOrderModal,
  listPickerRemove: ListPickerRemoveModal,
  publishEvent: PublishEventModal,
  removeBaseTariff: RemoveBaseTariffModal,
  removeEvent: RemoveEventModal,
  removeReferral: RemoveReferralModal,
  removeUserFromGroup: RemoveUserFromGroupModal,
  removeUserGroup: RemoveUserGroupModal,
  tariffOrderInfo: TariffOrderInfoModal,
};

export const Root = () => (
  <ApolloProvider client={apolloClient}>
    <GoblinProvider modalComponents={componentDictionary}>
      <Router history={history} router={router} renderer={renderer}>
        <Toaster />
      </Router>
    </GoblinProvider>
  </ApolloProvider>
);
