import React, { FC, useContext, useMemo, useState } from 'react';

import {
  StatusEnum,
  SubTariffsListQuery,
  usePurchaseStatsQuery,
  useSubTariffsListQuery,
} from '@/apolloGenerated';
import {
  FloatDriver,
  renderCellItemValue,
  ScrollXWrapper,
  StatItem,
  StatWrap,
  useOrganizerId,
} from '@/shared';
import { PurchasesContext, PurchasesContextType } from '@entities/Purchases';
import { getPaidOrdersCount } from '@entities/Purchases/libs/utils';
import { TariffTypeTranslate } from '@entities/Tariff/const';
import {
  Button,
  Divider,
  isNumber,
  Spacer,
  TableController,
} from '@letsdance/ui-kit';
import { readableStatus } from '@shared/libs/utils/format';
import { Head } from '@widgets/Layout';

import styles from './styles.module.scss';

const getStat = (stat?: number) => stat || 0;

export const PurchasesStats: FC = () => {
  const { productUuid } = useContext<PurchasesContextType>(PurchasesContext);
  const organizerId = useOrganizerId()!;
  const [isOpenTariffInfo, setIsOpenTariffInfo] = useState<boolean>(false);
  const { data } = usePurchaseStatsQuery({
    skip: !organizerId,
    variables: { organizerId, uuidProduct: productUuid },
  });
  const {
    data: dataTariff,
    error: errorTariffs,
    loading: loadingTariffs,
    refetch: refetchTariffs,
  } = useSubTariffsListQuery({
    skip: !productUuid,
    variables: {
      productUuid: productUuid!,
      status: [StatusEnum.Active, StatusEnum.Archive, StatusEnum.Draft],
    },
  });

  const amountStats = data?.statsOrders;
  const usersStats = data?.statsUsersWithOrders;
  const rationUsers =
    usersStats?.femaleCount && usersStats?.maleCount
      ? (usersStats.femaleCount / usersStats.maleCount).toFixed(2)
      : 0;

  const onCloseTariffInfo = () => {
    setIsOpenTariffInfo(false);
  };

  const rowTemplateTariffs = ({
    availableOrdersCount,
    baseTariff,
    name,
    ordersCount,
    statsOrdersCount,
    status,
    uuid,
  }: SubTariffsListQuery['subTariffsList']['rows'][0]) => ({
    availableOrders:
      isNumber(ordersCount) && isNumber(availableOrdersCount)
        ? `Доступно ${availableOrdersCount} из ${ordersCount}`
        : '-',
    awaitPayOrders: String(statsOrdersCount.awaitPay),
    info: renderCellItemValue({
      label: name || baseTariff!.name,
      value: baseTariff?.type ? TariffTypeTranslate[baseTariff?.type] : '-',
    }),
    paidOrders: String(
      getPaidOrdersCount(statsOrdersCount.paid, baseTariff?.type),
    ),
    refundOrders: String(statsOrdersCount.refunded),
    status: readableStatus(status),
    transferredOrders: String(statsOrdersCount.transferredProduct),
    uuid: uuid,
  });
  const itemsTariffs = useMemo(
    () => (dataTariff?.subTariffsList.rows || []).map(rowTemplateTariffs),
    [dataTariff],
  );

  return (
    <div className={styles.purchaseStats}>
      <div className={styles.purchaseStats__row}>
        <StatWrap>
          <StatItem
            label="Всего получено, ₽"
            value={getStat(amountStats?.turnover)}
            format
            fixed={false}
          />
        </StatWrap>
        <ScrollXWrapper>
          <StatWrap>
            <StatItem label="Пользователи" value={getStat(usersStats?.count)} />
            <Divider vertical />
            <StatItem label="Мужчины" value={getStat(usersStats?.maleCount)} />
            <Divider vertical />
            <StatItem
              label="Женщины"
              value={getStat(usersStats?.femaleCount)}
            />
            <Divider vertical />
            <StatItem label="Ж / М" value={rationUsers} />
          </StatWrap>
        </ScrollXWrapper>

        {productUuid && (
          <Button
            color="secondary"
            loading={loadingTariffs}
            style={{ minWidth: 190 }}
            onClick={() => setIsOpenTariffInfo(true)}>
            Информация по тарифам
          </Button>
        )}
      </div>
      <FloatDriver isOpen={isOpenTariffInfo} onClose={onCloseTariffInfo}>
        <Head title="Информация по тарифам" onBack={onCloseTariffInfo} />
        {!!dataTariff?.subTariffsList.rows.length && (
          <ScrollXWrapper>
            <TableController
              hasError={!!errorTariffs}
              onRefetch={errorTariffs && refetchTariffs}
              data={itemsTariffs}
              headers={[
                { hide: true, key: true, value: 'uuid' },
                { title: 'Данные', value: 'info' },
                { title: 'Статус ', value: 'status' },
                { title: 'Доступно', value: 'availableOrders' },
                { title: 'Куплено', value: 'paidOrders', width: 140 },
                {
                  title: 'Ожидает оплаты',
                  value: 'awaitPayOrders',
                  width: 140,
                },
                { title: 'Возвращено', value: 'refundOrders', width: 140 },
                { title: 'Перенесено', value: 'transferredOrders', width: 140 },
              ]}
            />
          </ScrollXWrapper>
        )}
        <Spacer size={10} />
      </FloatDriver>
    </div>
  );
};
