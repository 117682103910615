import { FC, useEffect, useState } from 'react';
import moment from 'moment-timezone';

import {
  ProductInput,
  ProductType,
  StatusEnum,
  SubTariffType,
  useRemoveTariffMutation,
} from '@/apolloGenerated';
import { FloatDriver, ListPicker, timezone, useModal } from '@/shared';
import { Maybe } from '@letsdance/ui-kit';
import { readableStatus } from '@shared/libs/utils/format';
import { TariffCreate, TariffEdit } from '@widgets/Tariff';

export interface EventTariffsControllerProps {
  eventData: ProductInput;
  initValues?: SubTariffType[];
  onChange?(values: SubTariffType[]): void;
  disabled?: boolean;
}
interface TariffEditor {
  readonly?: boolean;
  type: 'edit' | 'create';
  uuid?: string;
}
export const EventTariffsController: FC<EventTariffsControllerProps> = ({
  disabled,
  eventData,
  initValues,
  onChange,
}) => {
  const openTariffInfoModal = useModal('tariffOrderInfo');
  const [removeTariff] = useRemoveTariffMutation();
  const [editor, setEditor] = useState<Maybe<TariffEditor>>(null);

  const [value, setValue] = useState<SubTariffType[]>(initValues || []);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  const handleClose = () => {
    setEditor(null);
  };

  const handleChange = (val: SubTariffType[]) => {
    setValue(val);
    handleClose();
  };

  const handleAdd = (data: SubTariffType) => {
    handleChange([...value, data]);
    handleClose();
  };

  const handleEdit = (uuid: string, data: SubTariffType) => {
    const newValues = [...value];
    const elIndex = newValues.findIndex((el) => el.uuid === uuid);

    if (elIndex !== -1) {
      newValues[elIndex] = data;
      handleChange(newValues);
    }
  };

  const renderSubtitle = ({
    availableOrdersCount,
    baseTariff,
    endDate,
    ordersCount,
    startedDate,
    status,
  }: SubTariffType) => {
    let text = readableStatus(status);

    if (status === StatusEnum.Draft && startedDate) {
      text += ` до ${moment(startedDate).tz(timezone).format('DD MMM YYYY HH:mm')}`;
    }

    if (status === StatusEnum.Active && endDate) {
      text += ` до ${moment(endDate).tz(timezone).format('DD MMM YYYY HH:mm')}`;
    }

    if (ordersCount || baseTariff?.ordersCount) {
      text += ` (доступно ${availableOrdersCount} из ${ordersCount || baseTariff?.ordersCount})`;
    }

    return text;
  };

  return (
    <>
      <ListPicker
        initValues={value}
        labelRender={(item) => item.name || item.baseTariff?.name || ''}
        valueKey="uuid"
        renderSubtitle={renderSubtitle}
        onChange={handleChange}
        onAdd={() =>
          setEditor({
            type: 'create',
          })
        }
        onEdit={(val, readonly) =>
          setEditor({ readonly, type: 'edit', uuid: val.uuid })
        }
        onRemove={(val) => removeTariff({ variables: { uuid: val.uuid } })}
        disabled={disabled}
        onDetailInfo={(val) => openTariffInfoModal({ uuid: val.uuid })}
        texts={{
          addBtn: 'Добавить тариф',
          removeModal: {
            title: 'Удалить тариф?',
          },
        }}
      />
      <FloatDriver isOpen={!!editor} onClose={handleClose}>
        {editor?.type === 'edit' && (
          <TariffEdit
            eventData={eventData}
            uuid={editor.uuid!}
            disabled={editor.readonly}
            onClose={handleClose}
            onSuccess={(data) => handleEdit(editor.uuid!, data)}
          />
        )}
        {editor?.type === 'create' && (
          <TariffCreate
            eventData={eventData as ProductType}
            onClose={handleClose}
            onSuccess={(data) => handleAdd(data)}
          />
        )}
      </FloatDriver>
    </>
  );
};
