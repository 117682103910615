import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  Maybe,
  OrganizersListQuery,
  OrganizerSortKeys,
  useOrganizersListQuery,
} from '@/apolloGenerated';
import { RouterName } from '@/app/router';
import {
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
} from '@/shared';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

const headers = [
  { key: true, sortable: true, title: 'ID', value: 'id', width: 48 },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 180 },
  { sortable: true, title: 'Данные', value: 'name' },
  { title: 'События', value: 'productsCount' },
  { title: 'Продаж', value: 'ordersCount' },
  { title: 'Баланс, ₽', value: 'balance' },
  { title: 'Выручка, ₽', value: 'receipts' },
];

const pageSize = 15;
const DEFAULT_PAGE = 1;

export interface OrganizersTableProps {
  verified?: boolean;
}

export const OrganizersTable: FC<OrganizersTableProps> = ({ verified }) => {
  const { navigate } = useNavigate();
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrganizerSortKeys>(OrganizerSortKeys.Id);

  useEffect(() => {
    if (verified) {
      setPage(DEFAULT_PAGE);
    }
  }, [verified]);

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useOrganizersListQuery({
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      verified: verified || null,
    },
  });

  const data = curData || previousData;

  const rowTemplate = (
    organizer: OrganizersListQuery['organizersList']['rows'][0],
  ) => ({
    balance: formatAmount(organizer.balance, true),
    createdAt: renderCellDate({ timestamp: organizer.createdAt }),
    id: organizer.id,
    name: renderCellItemValue({
      label: organizer.name,
      value: organizer.telegram || '-',
    }),
    ordersCount: String(organizer.ordersCount),
    productsCount: String(organizer.productsCount),
    receipts: formatAmount(organizer.statsPayments.turnover, true),
  });
  const items = useMemo(
    () => (data?.organizersList?.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.organizersList?.count}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrganizerSortKeys);
        }}
        onClickRow={(e, organizer) =>
          navigate(RouterName.AdminOrganizerEdit, { id: organizer.id })
        }
        notResetPage
        onRefetch={error && refetch}
        hasError={!!error}
      />
    </div>
  );
};
