import { createElement } from 'react';

import { useCheckOrganizerSupplierInfoLazyQuery } from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { OrganizerPaymentLink } from '@features/Organizer';
import { toaster } from '@letsdance/ui-kit';

export const useOrganizerSupplierInfo = () => {
  const organizerId = useOrganizerId()!;
  const [checkSupplierInfo] = useCheckOrganizerSupplierInfoLazyQuery({
    variables: {
      id: organizerId,
    },
  });

  const checkOrganizerSupplierInfo = async (): Promise<void> => {
    const { data } = await checkSupplierInfo();

    if (!data?.organizer) {
      toaster.error({
        title: 'Не удалось получить данные организатора.',
      });

      return;
    }
    const hasTerminalKey = data.organizer.hasTerminalKey;
    const hasSupplierInfo = Object.values(
      data.organizer.supplierInfo || {},
    ).every(Boolean);

    if (!hasTerminalKey && !hasSupplierInfo) {
      toaster.warning({
        title: createElement(OrganizerPaymentLink),
      });
    }
  };

  return { checkOrganizerSupplierInfo };
};
