import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  ProductInput,
  ProductType,
  StatusEnum,
  SubTariffType,
  useEventQuery,
  useSubTariffsListQuery,
} from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  FormSection,
  ScrollXWrapper,
  useModal,
} from '@/shared';
import { RouterName } from '@app/router';
import { EventForm, useUpdateEvent } from '@entities/Event';
import { useOrganizerSupplierInfo } from '@entities/Organizer';
import {
  Divider,
  LoaderOverlay,
  Spacer,
  TabItem,
  Tabs,
  toaster,
} from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';
import { ReferralEvent } from '@widgets/Referral';
import { EventTariffsController } from '@widgets/Tariff';

export interface EventEditViewProps {
  params: {
    uuid: string;
  };
  query: {
    readonly?: boolean;
  };
}

enum EventTabs {
  Info = 'info',
  Tariffs = 'tariffs',
  Referral = 'referral',
}

export const EventEditView: FC<EventEditViewProps> = ({
  params: { uuid },
  query,
}) => {
  const { referralCreate } = useFeatureFlags();
  const [tab, setTab] = useState<EventTabs>(EventTabs.Info);
  const openPublishModal = useModal('publishEvent');
  const { navigate } = useNavigate();
  const [updateEvent, { error, loading: saveLoading }] = useUpdateEvent();
  const [eventData, setEventData] = useState<ProductInput>();
  const { checkOrganizerSupplierInfo } = useOrganizerSupplierInfo();
  const { data, loading, refetch } = useEventQuery({
    variables: {
      uuid,
    },
  });
  const { data: subTariffs } = useSubTariffsListQuery({
    fetchPolicy: 'network-only',
    variables: {
      productUuid: uuid,
      status: [StatusEnum.Draft, StatusEnum.Active],
    },
  });
  const product = data?.product;
  const isDraft = product?.status === StatusEnum.Draft;
  const isArchive = product?.status === StatusEnum.Archive;
  const isReadonly = !!query.readonly || isArchive;

  const onClose = () => {
    navigate(RouterName.AdminEvents);
  };
  const onPublish = () => {
    openPublishModal({ uuid });
  };
  const onSave = async () => {
    if (!eventData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateEvent({
      input: eventData,
      uuid,
    });
    await refetch();
    await checkOrganizerSupplierInfo();
  };

  const actions: ActionButton[] = isReadonly
    ? [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: () => navigate(RouterName.AdminEventEdit, { uuid }),
          label: 'Редактировать',
        },
      ]
    : [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: onSave,
          label: isDraft ? 'Сохранить черновик' : 'Сохранить изменения',
        },
        ...(isDraft
          ? [
              {
                handler: onPublish,
                label: 'Опубликовать',
              },
            ]
          : []),
      ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title={product?.name || ''} onBack={onClose} />
        <ScrollXWrapper>
          <Tabs initValue={tab} onChange={setTab}>
            <TabItem value={EventTabs.Info} label="Информация" />
            <TabItem value={EventTabs.Tariffs} label="Тарифы" />
            {referralCreate && (
              <TabItem value={EventTabs.Referral} label="Реферальные ссылки" />
            )}
          </Tabs>
        </ScrollXWrapper>
      </Container>
      <Divider />
      <Spacer size={8} />
      <Container slim>
        {data && (
          <>
            {tab === EventTabs.Info && (
              <>
                <ActionButtons actions={actions} hideDivider />
                <EventForm
                  initValues={data.product as ProductType}
                  onChange={setEventData}
                  errors={
                    ApolloErrorService.getFirstError(error?.graphQLErrors)
                      ?.extensions?.validationErrors
                  }
                  readonly={isReadonly}
                />
              </>
            )}
            {tab === EventTabs.Tariffs && (
              <FormSection title="Тарифы">
                <EventTariffsController
                  eventData={data.product}
                  disabled={isReadonly}
                  initValues={
                    (subTariffs?.subTariffsList.rows || []) as SubTariffType[]
                  }
                />
              </FormSection>
            )}
            {tab === EventTabs.Referral && (
              <ReferralEvent
                eventUuid={data.product.uuid}
                disabled={isReadonly}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};
