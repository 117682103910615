import React, { FC } from 'react';

import { StatusEnum } from '@/apolloGenerated';
import { Container, ScrollXWrapper } from '@/shared';
import { BaseTariffsTable } from '@features/Tariff';
import { Spacer } from '@letsdance/ui-kit';

export const BaseTariffsLists: FC = () => (
  <div>
    <Container>
      <Spacer size={12} />
      <ScrollXWrapper>
        <BaseTariffsTable status={[StatusEnum.Active]} />
      </ScrollXWrapper>
    </Container>
  </div>
);
