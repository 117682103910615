import { gql } from '@apollo/client';

export const baseTariffFragment = gql`
  fragment BaseTariffFields on BaseTariffType {
    createdAt
    updatedAt
    uuid
    name
    description
    query
    ordersCount
    price
    visible
    status
    type
    featureFlags {
      bonusPay
      refundOrder
      transferToUserOrder
      transferToProductOrder
      repeatBuyOrder
    }
    countSubTariffs
  }
`;

export const baseTariffsList = gql`
  ${baseTariffFragment}
  query baseTariffsList(
    $filters: BaseTariffListFilterInput
    $status: [StatusEnum!]
    $idOrganizer: Int!
  ) {
    baseTariffsList(
      status: $status
      filters: $filters
      idOrganizer: $idOrganizer
    ) {
      rows {
        ...BaseTariffFields
      }
      count
    }
  }
`;
export const baseTariff = gql`
  ${baseTariffFragment}
  query baseTariff($uuid: UUID!) {
    baseTariff(uuid: $uuid) {
      ...BaseTariffFields
    }
  }
`;

export const allowedUserGroupsForBaseTariff = gql`
  query allowedUserGroupsForBaseTariff($uuid: UUID!) {
    allowedUserGroupsForBaseTariff(uuid: $uuid) {
      uuid
    }
  }
`;

export const createBaseTariff = gql`
  ${baseTariffFragment}
  mutation createBaseTariff($input: BaseTariffInput!, $idOrganizer: Int!) {
    createBaseTariff(input: $input, idOrganizer: $idOrganizer) {
      ...BaseTariffFields
    }
  }
`;

export const updateBaseTariff = gql`
  ${baseTariffFragment}
  mutation updateBaseTariff($uuid: UUID!, $input: BaseTariffInput!) {
    updateBaseTariff(input: $input, uuid: $uuid) {
      ...BaseTariffFields
    }
  }
`;

export const duplicateBaseTariff = gql`
  ${baseTariffFragment}
  mutation duplicateBaseTariff($uuid: UUID!) {
    duplicateBaseTariff(uuid: $uuid) {
      ...BaseTariffFields
    }
  }
`;

export const removeBaseTariff = gql`
  mutation removeBaseTariff($uuid: UUID!) {
    removeBaseTariff(uuid: $uuid)
  }
`;

export const updateUsersBaseTariff = gql`
  mutation updateUsersBaseTariff($uuid: UUID!, $userGroupsUuids: [UUID!]!) {
    updateUserGroupsBaseTariff(uuid: $uuid, userGroupsUuid: $userGroupsUuids)
  }
`;
