import React, { FC, useState } from 'react';

import { UserGroupType, useUserGroupsQuery } from '@/apolloGenerated';
import { FormSection, useOrganizerId } from '@/shared';

export interface ContactTariffAccessSelectProps {
  initValue?: UserGroupType[];
  onChange(uuids: string[]): void;
}
export const ContactTariffAccessSelect: FC<ContactTariffAccessSelectProps> = ({
  initValue,
  onChange,
}) => {
  const idOrganizer = useOrganizerId()!;
  const { data: groupsData } = useUserGroupsQuery({
    variables: {
      idOrganizer,
    },
  });
  const [value, setValue] = useState<string[]>(
    initValue?.map((el) => el.uuid) || [],
  );

  const handleChange = (val: string[]) => {
    setValue(val);
    onChange(val);
  };

  console.log(handleChange, groupsData?.userGroups.rows);

  return (
    <FormSection
      title={`Группы пользователей (${value.length > 0 ? value.length : 'доступно всем'})`}>
      132
    </FormSection>
  );
};
