import { FC } from 'react';
import { Link, useNavigate } from 'react-justanother-router';

import { RouterName } from '@app/router';

export const OrganizerPaymentLink: FC = () => {
  const { urlFor } = useNavigate();

  return (
    <div>
      Для работы онлайн-оплаты необходимо заполнить
      <Link to={`${urlFor(RouterName.AdminProfile)}`} target="_blank">
        {' '}
        Платёжную информацию
      </Link>
    </div>
  );
};
