import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  EventsListQuery,
  Maybe,
  ProductSearchKeysEnum,
  ProductSortKeys,
  ProductType,
  StatusEnum,
  useEventsListQuery,
} from '@/apolloGenerated';
import {
  formatAmount,
  formatSort,
  renderCellDate,
  useOrganizerId,
} from '@/shared';
import { RouterName } from '@app/router';
import { renderCellEventName } from '@entities/Event';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

import { EventControl } from '../EventControl/EventControl';

const headersDefault = [
  { hide: true, key: true, value: 'uuid' },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 180 },
  { sortable: true, title: 'Дата обновления', value: 'updatedAt', width: 180 },
  { sortable: true, title: 'Вечеринка', value: 'name' },
  { sortable: true, title: 'Дата начала', value: 'startedAt', width: 180 },
  { sortable: true, title: 'Дата окончания', value: 'endAt', width: 180 },
  { title: 'Покупки', value: 'purchasedCount', width: 100 },
  { title: 'Посещено', value: 'visitedCount', width: 100 },
  { title: 'Выручка, ₽', value: 'receipts' },
  { title: ' ', value: 'settings', width: 48 },
];
const headersDraft = [
  { hide: true, key: true, value: 'uuid' },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 180 },
  { sortable: true, title: 'Дата обновления', value: 'updatedAt', width: 180 },
  { sortable: true, title: 'Вечеринка', value: 'name' },
  { title: ' ', value: 'settings', width: 48 },
];
const pageSize = 15;

export interface EventsTableProps {
  status: Maybe<StatusEnum>;
  searchValue: string;
  onInvite(productUuid: string): void;
  onCreateOrder(productUuid: string): void;
}

export const EventsTable: FC<EventsTableProps> = ({
  onCreateOrder,
  onInvite,
  searchValue,
  status,
}) => {
  const { navigate } = useNavigate();
  const organizerId = useOrganizerId();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<ProductSortKeys>(
    ProductSortKeys.UpdatedAt,
  );

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useEventsListQuery({
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
        search: {
          search: searchValue,
          searchBy: ProductSearchKeysEnum.Name,
        },
      },
      params: {
        organizerId,
      },
      status: status ? [status] : null,
    },
  });
  const data = curData || previousData;
  const rowTemplate = (event: EventsListQuery['productsList']['rows'][0]) => ({
    createdAt: renderCellDate({ timestamp: event.createdAt }),
    endAt: renderCellDate({ time: true, timestamp: event.endDate }),
    name: renderCellEventName({
      name: event.name,
      query: event.query,
      startedDate: event.startedDate,
    }),
    purchasedCount: String(event.purchasedCount),
    receipts: formatAmount(event.statsPayments.turnover),
    settings: (
      <EventControl
        event={event as ProductType}
        status={event.status}
        onInvite={() => onInvite(event.uuid)}
        onCreateOrder={() => onCreateOrder(event.uuid)}
      />
    ),
    startedAt: renderCellDate({ time: true, timestamp: event.startedDate }),
    updatedAt: renderCellDate({ timestamp: event.updatedAt }),
    uuid: event.uuid,
    visitedCount: String(event.visitedCount),
  });
  const items = useMemo(
    () => (data?.productsList.rows || []).map(rowTemplate),
    [data],
  );

  const headers = useMemo(() => {
    if (status === StatusEnum.Draft) {
      return headersDraft;
    }

    return headersDefault;
  }, [status]);

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        total={data?.productsList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as ProductSortKeys);
        }}
        onClickRow={(e, event) =>
          navigate(RouterName.AdminEventEdit, { uuid: event.uuid })
        }
        notResetPage
        onRefetch={error && refetch}
      />
    </div>
  );
};
